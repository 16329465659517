<template>
  <div class="layer-page-div">
    <div class="big-img-div" v-show="isShowBigImg" @click="onHideBigImgDiv">
      <img ref="bigImg" src="" alt=""/>
    </div>
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">名称：</div>
          <div class="item-value-div">
            <validation-provider rules="required|minmax:2,10" v-slot="{ errors }" name="partsName">
              <label><input v-model="partsMsg.name" type="text" maxlength="10"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">副标题：</div>
          <div class="item-value-div">
            <validation-provider rules="minmax:1,10" v-slot="{ errors }" name="subtitle">
              <label><input v-model="partsMsg.subtitle" type="text" maxlength="10"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">展示类型：</div>
          <div class="item-value-div">{{ getPartsType() }}</div>
        </div>
        <div v-show="isTable" class="item-div">
          <div class="item-name-div">列表类型：</div>
          <div class="item-value-div">{{ isSpecial ? "特殊" : "普通"}}</div>
        </div>
        <div v-show="isTable && isSpecial" class="item-other-div">
          <div class="item-name-div">特殊表头：</div>
          <div class="item-table-div">
            <label v-for="item in partsMsg.special">{{ item }}</label>
          </div>
        </div>
        <div v-show="isTable" class="item-other-div">
          <div class="item-name-div">普通表头：</div>
          <div class="item-table-div">
            <label v-for="item in partsMsg.header">{{ item }}</label>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">展示图片：</div>
          <div v-show="isShowAddImg">
            <label class="upload-img-label" @change="onAddImg($event)">
              <img class="cursor-el" src="../../assets/picture.png" alt="" />
              <input class="cursor-el" type="file" />
            </label>
          </div>
          <div v-show="!isShowAddImg" class="preview-img-div">
            <img class="del-img" src="../../assets/del.png" alt="" @click="onDelImg">
            <img ref="img" src="" alt="" @click="onShowBigImgDiv($event)">
          </div>
        </div>
      </validation-observer>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="保存" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postMachineryPartsEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    partsBase: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      partsMsg: {
        name: this.partsBase.name,
        subtitle: this.partsBase.subtitle,
        type: this.partsBase.type,
        header: this.partsBase.type === 2 ? this.partsBase.header.split(";")[0].split(",") : [],
        special: this.partsBase.type === 2 ? (this.partsBase.header.split(";").length > 1 ? this.partsBase.header.split(";")[1].split(",") : []) : []
      },
      isTable: this.partsBase.type === 2,
      isSpecial: this.partsBase.type === 2 && this.partsBase.header.split(";").length > 1,
      isDelImg: false,
      partsImg: null,
      tipMsg: "",
      isShowAddImg: true,
      isShowBigImg: false
    }
  },
  created() {
    if(Object.prototype.hasOwnProperty.call(this.partsBase, "img")){
      this.isShowAddImg = false;
      this.$nextTick(() => {
        this.$refs.img.src = `${this.$imgBaseURL}/${this.partsBase.img}`;
      });
    }else{
      this.isShowAddImg = true;
    }
  },
  methods: {
    getPartsType() {
      return this.partsMsg.type === 1 ? "普通" : (this.partsMsg.type === 2 ? "列表" : "标签");
    },
    onAddImg(event) {
      if(event.target.files.length < 1){
        return;
      }

      if(!/\.(jpg|jpeg|png)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.$layer.msg("图片类型仅支持jpg、jpeg和png格式");
        return;
      }

      let img = event.target.files[0];
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function() {
        that.$refs.img.src = reader.result;
        that.partsImg = img;
        that.isShowAddImg = false;
        event.target.value = null;
      };
      reader.onerror = function() {
        this.onDelImg();
        that.$layer.msg("读取文件失败，请重试");
      };
    },
    onDelImg() {
      this.partsImg = null;
      this.$refs.img.src = "";
      this.isShowAddImg = true;
      this.isDelImg = true;
    },
    onShowBigImgDiv(event) {
      this.$refs.bigImg.src = event.target.src;
      this.isShowBigImg = true;
    },
    onHideBigImgDiv() {
      this.isShowBigImg = false;
    },
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onSave() {
      this.$refs.form.validate().then(res => {
        if(res){
          let formData = new FormData();
          formData.append("id", this.partsBase.id);
          formData.append("name", this.partsMsg.name);
          formData.append("subtitle", this.partsMsg.subtitle);
          formData.append("delImg", this.isDelImg ? "1" : "0");
          if(this.partsImg != null){
            formData.append("file", this.partsImg);
          }

          postMachineryPartsEdit(formData)
            .then(() => {
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .big-img-div {
    position: absolute;
    width: 100%;
    height: calc(100% - 42px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      align-items: center;
    }
  }
  .layer-content-div {
    width: 90%;
    margin: 10px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        text-align: right;
      }
      .item-value-div {
        input {
          width: 168px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
      }
      .preview-img-div {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          vertical-align: middle;
        }
        .del-img {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .item-other-div {
      display: flex;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        line-height: 35px;
        text-align: right;
      }
      .item-table-div {
        display: flex;
        align-items: center;
        label {
          padding: 5px;
          border-top: 1px solid #dcdcdc;
          border-right: 1px solid #dcdcdc;
          border-bottom: 1px solid #dcdcdc;
        }
        label:first-child {
          border-left: 1px solid #dcdcdc;
        }
      }
    }
    .err-tips {
      margin-left: 5px;
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>